@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';

$primary: $green-500;
$secondary: $pink-500;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
);

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.license-navbar-text {
  font-size: small;
}

.table-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.form-padding-shadow {
  padding: 10px;
}

.card {
  margin-bottom: 15px;
}

// Sidebar starts here
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
:root {
  --header-height: 3rem;
  --nav-width: 75px;
  --first-color: #388e3c;
  --first-color-light: #c8e6c9;
  --white-color: #f7f6fb;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --show-navbar-width: 245px;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -35%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 0rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
  padding: 0.5rem 0 0.5rem 1.25rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--white-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.show {
  left: 0;
}

.active {
  color: var(--white-color);
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

body {
  margin: calc(var(--header-height) + 1rem) 0 0 0;
}

.header {
  height: calc(var(--header-height) + 1rem);
  padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
}

.header_img {
  width: 40px;
  height: 40px;
}

.header_img img {
  width: 45px;
}

.l-navbar {
  left: 0;
}

.show-nav-bar {
  width: var(--show-navbar-width);
}

.header-pd {
  padding-left: 265px;
}

@media screen and (min-width: 769px) {
  .l-navbar {
    padding: 1rem 1rem 0 0;
  }
  .body-pd {
    padding-left: calc(var(--show-navbar-width) + 1rem);
  }
  body {
    padding-left: calc(var(--nav-width));
  }
}

@media screen and (max-width: 768px) {
  .navbar-text {
    visibility: hidden;
  }
  :root {
    --nav-width: 0px;
  }
  .container-blur {
    filter: blur(4px);
  }
}

// Sidebar ends here

.control-group button,
.control-group a {
  margin-bottom: 0.3rem;
}

.dropdown {
  position: static;
}

legend {
  font-size: medium;
}

#div_id_contractor .select2-container {
  width: 100% !important;
}

.btn-header {
  margin-bottom: 0.3rem;
}
